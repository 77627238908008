<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logo-wide.png')"
              max-height="200px"
              max-width="200px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">Welcome to Customer Portal</p>
          <p class="mb-2 text-center" v-if="!pageAlert.show">Please sign-in to your account</p>
          <v-alert v-model="pageAlert.show" border="left" class="my-2" :type="pageAlert.type" dismissible>{{pageAlert.message}}</v-alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="refForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="username"
              :rules="usernameRules"
              outlined
              label="Username"
              placeholder="Username"
              hide-details
              class="mb-3"
              v-on:keyup.enter="doLogin"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              v-on:keyup.enter="doLogin"
            ></v-text-field>

            <v-btn block :disabled="!valid" @click="doLogin" ref="btnLogin" color="primary" class="mt-6">Login</v-btn>

            <div class="d-flex align-center justify-space-between flex-wrap mt-2 text-center">
              <!-- <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"></v-checkbox> -->

              <!-- forgot link -->
              <v-btn to="/forgot" text link block class="mt-1">Forgot Password?</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <!-- <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    /> -->

    <!-- tree -->
    <!-- <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img> -->

    <!-- tree  -->
    <!-- <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data: function () {
    return {
      
      isPasswordVisible : false,
      socialLink : [
        {
          icon: mdiFacebook,
          color: '#4267b2',
          colorInDark: '#4267b2',
        },
        {
          icon: mdiTwitter,
          color: '#1da1f2',
          colorInDark: '#1da1f2',
        },
        {
          icon: mdiGithub,
          color: '#272727',
          colorInDark: '#fff',
        },
        {
          icon: mdiGoogle,
          color: '#db4437',
          colorInDark: '#db4437',
        },
      ],
      pageAlert: {
        show: false,
        message: '',
        type: 'info',
      },
      valid: false,
      username: '',
      usernameRules: [v => !!v || 'Username is required'],
      password: '',
      passwordRules: [v => !!v || 'Password is required'],
      iToken: '',
      

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  
  methods: {
    validate() {
      this.$refs.refForm.validate()
    },
    reset() {
      this.pageAlert.show = false
      this.$refs.refForm.reset()
    },
    resetValidation() {
      this.$refs.refForm.resetValidation()
    },
    doLogin() {
      // console.log(this.valid);
      this.validate()
      // console.log(this.valid);
      if (this.valid == true) {
        // this.$store.state.showLoadingDialog = true;

        // var myaxios = axios.create({
        //   baseURL : 'http://192.168.2.20:8090/api/'
        // });

        this.pageAlert.show = false

        var formData = new FormData()
        formData.append('username', this.username)
        formData.append('password', this.password)

        this.$axios.post('auth/login', formData)
          .then(res => {
            if (res.data.status == 'success') {
              this.$store.state.apiToken = res.data.data.token
              this.$store.state.loginInfo.username = res.data.data.username
              this.$store.state.loginInfo.firstName = res.data.data.first_name
              this.$store.state.loginInfo.lastName = res.data.data.last_name
              this.$store.state.loginInfo.profileImage = res.data.data.user_img
              this.$store.state.loginInfo.email = res.data.data.email

              this.$store.state.isLoggedIn = true
              // this.$store.state.showLoadingDialog = false;
              this.$router.push('/')
              // window.location.assign("/home");
            } else {
              this.pageAlert.show = true
              this.pageAlert.type = 'error'
              this.pageAlert.message = res.data.message
              // this.$store.state.showLoadingDialog = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
              this.showLoadingComponent = false
              this.pageAlert.show = true
              this.pageAlert.type = 'error'
              this.pageAlert.message = 'Internal Server Error'
          })
      }
    },
  },
  watch: {
    iToken(newVal) {
      localStorage.appToken = newVal
    },
  },
  beforeCreate() {
    this.$store.state.showNavBar = false
    this.$store.state.hideFooter = true
  },
  beforeMount() {
    this.$store.state.showPageOverlay = true
  },
  mounted() {
    this.$store.state.showPageOverlay = false
    document.title = 'Customer Login'
    this.$store.state.pageTitle = 'Customer Login'
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
